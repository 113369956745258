import PropTypes from 'prop-types';
import { useRef } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { Link, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
// _mock
import { _socials } from 'src/_mock';
// utils
import { fNumber } from 'src/utils/format-number';
// components
import Iconify from 'src/components/iconify';
//
import ProfilePostItem from './profile-post-item';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import { useGetAllBusinessQuery, useGetBusinessByIdQuery } from 'src/store/Reducer/business';
import { Avatar, IconButton, ListItemText, Skeleton, Tooltip } from '@mui/material';
import DisplaySubcategories from '../category/subcategories';
import DisplayBusinessSubcategories from './display-business-subcategories';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function ProfileHome() {

  const { branch } = useMockedBranch();

  const fileRef = useRef(null);

  const { data, status } = useGetBusinessByIdQuery(branch._id);



  console.log('Business Info = ', data)

  const { socialLinks } = data ?? {};


  console.log("socialLinks = ", socialLinks)
  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: (theme) => theme.spacing(2, 3, 3, 3),
        }}
      >
        <Typography color="info" variant="h5" sx={{ mb: 2 }}>
          Categories
        </Typography>
        {status === 'pending' ? (
          <Skeleton variant="rounded" maxWidth={'100%'} height={'6rem'} />
        ) : (
          <DisplayBusinessSubcategories subcategories={data?.categories} />
        )}{' '}
      </Grid>
      <Grid
        xs={12}
        md={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: (theme) => theme.spacing(2, 3, 3, 3),
        }}
      >
        <Typography color="info" variant="h5" sx={{ mb: 2 }}>
          About
        </Typography>
        {status === 'pending' ? (
          <Stack spacing={2}>
            <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
            <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
            <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
            <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
            <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
            <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Item sx={{ display: 'flex' }}>
              <Iconify icon="solar:call-chat-rounded-bold-duotone" sx={{ mr: 2 }} />
              <Typography>{data?.contactNumber}</Typography>
            </Item>
            <Item sx={{ display: 'flex' }}>
              <Iconify icon="devicon:facebook" color="#64b5f6" sx={{ flexShrink: 0, mr: 2 }} />
              <a
                href={`https://www.facebook.com/${socialLinks?.facebook}` ?? '#'}
                style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {socialLinks?.facebook ? socialLinks?.facebook : 'Provide Social Links'}
              </a>
            </Item>
            <Item sx={{ display: 'flex' }}>
              <Iconify icon="skill-icons:instagram" color="#64b5f6" sx={{ flexShrink: 0, mr: 2 }} />
              <a
                href={`https://www.instagram.com/${socialLinks?.instagram}` ?? '#'}
                style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {socialLinks.instagram ? socialLinks.instagram : 'Provide Social Links'}
              </a>
            </Item>
            <Item sx={{ display: 'flex' }}>
              <Iconify icon="logos:twitter" color="#64b5f6" sx={{ flexShrink: 0, mr: 2 }} />
              <a
                href={`https://twitter.com/${socialLinks?.twitter}` ?? '#'}
                style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {socialLinks?.twitter ? socialLinks.twitter : 'Provide Social Links'}
              </a>
            </Item>
            <Item sx={{ display: 'flex' }}>
              <Iconify
                icon="solar:link-line-duotone"
                color="#64b5f6"
                sx={{ flexShrink: 0, mr: 2 }}
              />
              <a
                href={socialLinks?.website ?? '#'}
                style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {socialLinks?.website ? socialLinks?.website : 'Provide Social Links'}
              </a>
            </Item>

            <Item sx={{ display: 'flex' }}>
              <Iconify icon="solar:map-point-wave-bold-duotone" sx={{ mr: 2 }} />
              <Tooltip title={data?.address?.line1}>
                <Typography
                  sx={{
                    maxWidth: '15rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {data?.address?.line1}
                </Typography>
              </Tooltip>
            </Item>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}

ProfileHome.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
};
